<template>
    <v-container fluid>
        <div class="d-flex mb-4">
            <v-btn x-large class="mr-auto" :to="{ name: leadName }">BACK</v-btn>
            <template v-if="lead.status !== 'closed'">
                <div>
                    <ConfirmSpam
                        v-if="lead.is_spam"
                        title="Delete lead"
                        message="Are you sure you want to delete this lead?"
                        btnlabel="Delete lead"
                        color="red"
                        okbtncolor="error"
                        @ok="delLead"
                    />
                    <ConfirmSpam
                        v-if="!lead.is_spam"
                        title="Reject lead"
                        message="Are you sure you want to reject this lead?"
                        btnlabel="Reject"
                        color="red"
                        okbtncolor="error"
                        @ok="markAsSpam(true)"
                    />
                </div>
                <div>
                    <ConfirmSpam
                        v-if="lead.is_spam"
                        title="Reopen lead"
                        message="Are you sure you want to reopen this lead?"
                        btnlabel="Reopen"
                        color="info"
                        okbtncolor="info"
                        @ok="markAsSpam(false)"
                    />
                    <!-- <v-btn
                    v-if="lead.is_spam"
                @click="markAsSpam(false)"
                    x-large
                    color="error"
                    class="ml-auto"
                    >Unspam</v-btn> -->
                    <ConfirmSpam
                        v-if="!lead.is_spam"
                        title="Close Deal"
                        message="Are you sure you want to close this deal?"
                        btnlabel="Close deal"
                        color="info"
                        okbtncolor="info"
                        @ok="closeDeal"
                    />
                </div>

            </template>
            <!-- <v-btn
                v-if="!lead.is_spam"
                @click="closeDeal"
                x-large
                color="info"
                class="ml-2"
                >Close deal</v-btn> -->
        </div>
        <v-divider />
        <h3 class="mt-4">Date of inquiry</h3>
        <v-col v-if="lead.created">
            {{ lead.created.substr(0, 10) }}
        </v-col>

        <h3 class="mt-4">Name</h3>
        <v-col>
            {{ lead.name }}
        </v-col>

        <h3 class="mt-4">Email address</h3>
        <v-col>
            {{ lead.email }}
        </v-col>

        <h3 class="mt-4">Phone</h3>
        <v-col>
            {{ lead.phone_number }}
        </v-col>

        <template>
            <h3 class="mt-4">Referrer</h3>
            <v-col>
                {{ lead.referrer }}
            </v-col>
        </template>

        <template v-if="lead.lead_type == 'listing'">
            <h3 class="mt-4">Listing URL</h3>
            <v-col>
                <a
                    :href="
                        `https://www.yourbrokerlisting.com${lead.listing_url}`
                    "
                    target="_blank"
                    >{{
                        `https://www.yourbrokerlisting.com${lead.listing_url}`
                    }}</a
                >
            </v-col>
        </template>
        <template
            v-if="
                lead.lead_type == 'sell_lease' || lead.lead_type == 'appraisal'
            "
        >
            <h3 class="mt-4">Property location</h3>
            <v-col>
                {{ lead.property_location }}
            </v-col>
        </template>

        <template
            v-if="
                lead.lead_type == 'sell_lease' || lead.lead_type == 'appraisal'
            "
        >
            <h3 class="mt-4">Property type</h3>
            <v-col class="text-capitalize">
                {{ lead.property_type }}
            </v-col>
        </template>

        <template v-if="lead.lead_type == 'sell_lease'">
            <h3 class="mt-4">Transaction type</h3>
            <v-col class="text-capitalize">
                {{ lead.transaction_type }}
            </v-col>
        </template>

        <h3 class="mt-4">Status</h3>
        <v-col class="text-capitalize">
            {{ lead.status }}
        </v-col>

        <h3 class="mt-4">Assignee</h3>
        <v-col>
            <template v-if="!openAssigneeForm">
                <h3>{{ lead.assignee }}</h3>
            </template>
            <v-btn
                v-if="lead.assignee == '' && !openAssigneeForm"
                x-small
                color="primary"
                @click="openAssigneeForm = true"
            >
                Set assignee
            </v-btn>
            <v-btn
                v-else-if="lead.assignee !== '' && !openAssigneeForm"
                x-small
                color="primary"
                @click="openAssigneeForm = true"
            >
                Change assignee
            </v-btn>
            <template v-if="openAssigneeForm">
                <div class="d-flex mt-2 align-center">
                    <v-text-field
                        type="text"
                        label="Assignee"
                        v-model="assignee"
                        required
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                    <v-btn
                        @click="updateAssignee"
                        large
                        color="primary"
                        class="ml-2"
                        >Update Assignee</v-btn
                    >
                    <v-btn
                        @click="openAssigneeForm = false"
                        icon
                        color="error"
                        large
                        class="ml-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-col>

        <template v-if="['sell_lease', 'contact_us'].includes(lead.lead_type)">
            <h3 class="mt-4">Admin Listing Url</h3>
            <v-col class="text-capitalize">
                <template v-if="!openListingForm">
                    <h3 class="text-lowercase">{{ lead.listing_url }}</h3>
                </template>
                <v-btn
                    v-if="lead.listing_url == '' && !openListingForm"
                    x-small
                    color="primary"
                    @click="openListingForm = true"
                >
                    Set listing url
                </v-btn>
                <v-btn
                    v-else-if="
                        lead.listing_url !== '' && !openListingForm
                    "
                    x-small
                    color="primary"
                    @click="openListingForm = true"
                >
                    Change listing url
                </v-btn>
                <template v-if="openListingForm">
                    <div class="d-flex mt-2 align-center">
                        <v-text-field
                            type="text"
                            label="URL"
                            v-model="admin_listing_url"
                            required
                            outlined
                            hide-details="auto"
                        ></v-text-field>
                        <ConfirmUpdate
                            title="Set URL"
                            message='Setting the admin listing URL means that the property has been added to our listing by the assignee. Please use the URL of our admin listing view page (click the "View" button of the listing). Do you want to set the URL?'
                            btnlabel="Update url"
                            color="info"
                            okbtncolor="error"
                            @ok="updateListingURL"
                        />
                        <!-- <v-btn
                            @click="updateListingURL"
                            large
                            color="primary"
                            class="ml-2"
                            >Update url</v-btn
                        > -->
                        <v-btn
                            @click="openListingForm = false"
                            icon
                            color="error"
                            large
                            class="ml-2"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-col>
        </template>

        <h3 class="mt-4">Message</h3>
        <v-col>
            {{ lead.message }}
        </v-col>

        <h3 class="mt-4">History</h3>
        <v-col>
            <template v-if="sortedHistories.length > 0">
                <v-list>
                    <template v-for="(item, index) in sortedHistories">
                        <v-list-item
                            v-if="item.status == 'set_assignee'"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle class="text--primary"
                                    >This lead has been assigned to
                                    <strong>{{ item.assignee }}</strong> on
                                    <strong>{{ item.created | moment }}</strong>
                                    at
                                    <strong>{{
                                        item.created | momenttime
                                    }}</strong
                                    >.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-if="item.status == 'added'"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                    This property has been added to our listing by
                                    <strong>{{ item.assignee }}</strong>
                                    on <strong>{{ item.created | moment }}</strong>
                                    at <strong>{{ item.created | momenttime }}</strong
                                    >.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-if="item.status == 'closed'"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                    This deal has been closed by
                                    <strong>{{ item.assignee }}</strong>.
                                    <div class="mt-4">
                                        This lead has been marked as closed on
                                        <strong>{{
                                            item.created | moment
                                        }}</strong>
                                        at
                                        <strong>{{
                                            item.created | momenttime
                                        }}</strong
                                        >.
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-if="item.status == 'spam'"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                    This lead has been rejected on
                                    <strong>{{ item.created | moment }}</strong>
                                    at
                                    <strong>{{
                                        item.created | momenttime
                                    }}</strong
                                    >.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-if="item.status == 'unspam'"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                    This lead has been moved back to Open on
                                    <strong>{{ item.created | moment }}</strong>
                                    at
                                    <strong>{{
                                        item.created | momenttime
                                    }}</strong
                                    >.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-if="item.status == 'set_url'"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                    The property has been added to our listing by
                                    <strong>{{ item.assignee }}</strong>
                                    on <strong>{{ item.created | moment }}</strong>
                                    at <strong>{{ item.created | momenttime }}</strong>.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="index < histories.length - 1"
                            :key="index"
                        ></v-divider>
                    </template>
                </v-list>
            </template>
            <template v-else>No history.</template>
        </v-col>
        <div>
            <h3 class="mt-4">Public link</h3>
            <v-col>
                <a
                    :href="`https://www.yourbrokerlisting.com/lead/${lead.id}`"
                    target="_blank"
                    >{{ `https://www.yourbrokerlisting.com/lead/${lead.id}` }}</a
                >
            </v-col>
        </div>
    </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import ConfirmSpam from '@/components/modal/ConfirmModal.vue'
import ConfirmClose from '@/components/modal/ConfirmModal.vue'
import ConfirmUpdate from '@/components/modal/ConfirmModal.vue'

export default {
  name: 'LeadView',
  components: {
    ConfirmSpam,
    ConfirmClose,
    ConfirmUpdate
  },
  data () {
    return {
      lead: {},
      openAssigneeForm: false,
      assignee: '',
      openListingForm: false,
      admin_listing_url: '',
      agent: null
    }
  },
  computed: {
    histories () {
      return this.lead
        ? this.lead.history
          ? this.lead.history
          : []
        : []
    },
    sortedHistories () {
      return this.histories.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created)
      })
    },
    leadName () {
      switch (this.lead.lead_type) {
        case 'contact_us':
          return 'contact-leads'
          break
        case 'listing':
          return 'listing-leads'
          break
        case 'sell_lease':
          return 'sell-leads'
          break
        default:
          return 'appraisal-leads'
          break
      }
    }
  },
  methods: {
    ...mapMutations(['setLeadPage']),
    ...mapActions([
      'getLead',
      'markLeadAsSpam',
      'setleadAssignee',
      'setListingURL',
      'closeLead',
      'deleteLead',
      'getSalesperson',
      'getBroker'
    ]),
    updateAssignee () {
      this.setleadAssignee({
        id: this.$route.params.id,
        assignee: this.assignee
      }).then(response => {
        this.lead.assignee = response.data.assignee
        this.lead.status = response.data.status
        this.lead.history = response.data.history
        this.openAssigneeForm = false
      })
    },
    updateListingURL () {
      this.setListingURL({
        id: this.$route.params.id,
        listing_url: this.admin_listing_url
      }).then(response => {
        console.log('response', response)
        this.lead.listing_url = response.data.listing_url
        this.lead.history = response.data.history
        this.openListingForm = false
      })
    },
    closeDeal () {
      this.closeLead({
        id: this.$route.params.id,
        status: 'closed'
      }).then(response => {
        this.lead.status = response.data.status
        this.lead.history = response.data.history
      })
    },
    delLead () {
      this.deleteLead({ id: this.$route.params.id, name: this.leadName })
        .then((response) => {
          console.log('response', response)
        })
    },
    markAsSpam (value) {
      const status = value ? 'cancelled' : 'open'
      this.markLeadAsSpam({
        id: this.$route.params.id,
        is_spam: value,
        status: status
      }).then(response => {
        this.lead.is_spam = response.data.is_spam
        this.lead.status = response.data.status
        this.lead.history = response.data.history
      })
    }
  },
  created () {
    this.getLead({ id: this.$route.params.id }).then(response => {
      this.lead = response.data
      let page = ''
      switch (this.lead.lead_type) {
        case 'contact_us':
          page = 'contact-leads'
          break
        case 'sell_lease':
          page = 'sell-leads'
          break
        case 'appraisal':
          page = 'appraisal-leads'
          break
        case 'listing':
          page = 'listing-leads'
          break
      }
      this.setLeadPage(page)
      if (response.data.agent) {
        this.getBroker({ id: 4 })
          .then((broker) => {
            console.log('response:broker', broker)
            this.agent = broker.data
          })
          .catch(() => {
            this.getSalesperson({ id: response.data.agent })
              .then((sales) => {
                console.log('response:salesagent', sales)
                this.agent = sales.data
              })
              .catch((error) => console.log('error', error))
          })
      }
    })
  },
  beforeDestroy () {
    this.setLeadPage('')
  },
  filters: {
    moment: function (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    momenttime: function (date) {
      return moment(date).format('hh:mmA')
    }
  }
}
</script>
<style lang="scss" scoped></style>
