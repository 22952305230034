<template>
    <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="color"
            dark
            x-large
            v-bind="attrs"
            v-on="on"
            class="ml-1"
          >
            {{ btnlabel }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 lighten-2">
            {{ title }}
          </v-card-title>

          <v-card-text>
            {{ message }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="light"
              text
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="okbtncolor"
              @click="ok"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
    export default {
        name: 'ConfirmModal',
        props: {
            title: String,
            message: String,
            btnlabel: String,
            color: String,
            okbtncolor: String
        },
        data() {
            return {
                dialog: false
            }
        },
        methods: {
            cancel() {
                this.dialog = false
            },
            ok() {
                this.$emit('ok')
                this.dialog = false
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
